module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://popularhealth.com","stripQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-pinterest-twitter-facebook/gatsby-browser.js'),
      options: {"plugins":[],"delayTimer":1000,"pinterest":{"enable":true,"tall":true,"round":false},"twitter":{"enable":true,"containerSelector":null,"handle":null,"showFollowButton":true,"showTimeline":true,"showFollowerCount":true,"timelineTweetCount":1,"width":null,"height":null,"noHeader":true,"noFooter":true,"noBorders":true,"noScrollbar":true,"transparent":true},"facebook":{"enable":true,"containerSelector":null,"profile":null,"width":null,"height":null,"tabs":"timeline, events, messages","hideCover":false,"showFacepile":true,"smallHeader":false,"adaptContainerWidth":true}},
    },{
      plugin: require('../../contentcurator-gatsby-template/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
